import React from "react";

const Map = ({ width = "100%", height = 450 }) => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d756.6443057366258!2d22.946556829230904!3d40.661248998708636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a839ce709e303b%3A0x7101523b86c0d0d9!2zzqPOkc6hzpTOkc6hzpnOlM6XzqMgzqbOmc6bzpHOoc6VzqTOn86jIE1E!5e0!3m2!1sen!2sgr!4v1612951272836!5m2!1sen!2sgr"
        width={width}
        height={height}
        frameBorder="0"
        aria-hidden="false"
        title="Map"
      ></iframe>
    </div>
  );
};

export default Map;
