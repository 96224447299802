import * as React from 'react'

import Layout from '../components/layout'
import Map from '../components/common/map'
import * as contactStyles from './epikoinonia.module.css'

const IndexPage = () => {
   return (
      <Layout>
         <Map height={500} />
         <div className={`container mx-auto max-w-7xl px-4 md:px-6 lg:px-8 mb-20 ${contactStyles.contact}`}>
            <h1 className={contactStyles.contactTitle}>Επικοινωνία</h1>
            <h3 className={contactStyles.contactText}>
               Καλέστε μας στο 2310 603 356
            </h3>
            <div className={contactStyles.contactContent}>
               <div>
                  <div>
                     <div>Διεύθυνση: Θ. Καρυπίδη 4, Πολίχνη, Θεσσαλονίκη</div>
                     <div>Γραφείο: (+30) 2310 603 356</div>
                     <div>Κινητό: (+30) 6947 807 229</div>
                     <div>Email: filar73@gmail.com</div>
                  </div>
               </div>
               <div>
                  <div>
                     <h3>
                        <span>Ωράριο Λειτουργίας</span>
                     </h3>
                     <div>
                        <div className={contactStyles.contactTimeline}>
                           <div>Δευτέρα - Τρίτη</div>
                           <div>
                              09:30 - 14:00 <br /> 17:30 - 20:30
                           </div>
                        </div>
                        <div className={contactStyles.contactTimeline}>
                           <div>Τετάρτη - Παρασκευή</div>
                           <div>17:30 - 20:30</div>
                        </div>
                        <div className={contactStyles.contactTimeline}>
                           <div>Σαββατο - Κυριακή</div>
                           <div>Κλειστά</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Layout>
   )
}

export default IndexPage

export function Head() {
   return (
      <title>Επικοινωνία | Σαρδαρίδης Φιλάρετος Ανδρολόγος Ουρολόγος</title>
   )
}